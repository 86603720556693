export const cardTypes = {
  MASTERCARD: "MasterCard",
  VISA: "Visa",
  AMEX: "Amex"
}

export const emailsAccepted = [
  "@icloud.com",
  "@gmail.com",
  "@outlook.com",
  "@yahoo.com",
  "@protonmail.com", 
  "@me.com"
]

export const emailIndMap = {
  0: "icloud",
  1: "gmail",
  2: "outlook",
  3: "yahooo",
  4: "protonmail",
  5: "me"
}