export const baseMediaUrl = process.env.REACT_APP_MEDIA_URL || '';

// A Day with Black Jet Section Videos
export const aDayWithBlackJetVideo = baseMediaUrl + '/images/Sequence16.mp4';

// FlyFreely Section Videos
export const flyFreelyVideo = baseMediaUrl + '/images/videoblackjet/Black_JET_LOW_BIT_RATE.mp4';

// FlyFreely Section Images
export const flightChair = baseMediaUrl + '/images/Picture.svg';

// Booking Section Images
export const virtualTour = baseMediaUrl + '/images/virtual-tour.svg';

// Tour Section Images
//! not working
export const panoMiddleRear = baseMediaUrl + '/images/Pano Middle Rear.jpg';
export const PanoMiddle = baseMediaUrl + '/images/Pano Middle.jpg';
export const PanoRear = baseMediaUrl + '/images/Pano Rear.jpg';
export const PanoLav = baseMediaUrl + '/images/Pano Lav.jpg';
export const PanoFront = baseMediaUrl + '/images/Pano Front.jpg';

//About Us Section Images
export const aboutUsLeftImg = baseMediaUrl + '/images/about-left-img.svg';
