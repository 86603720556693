import React, { memo, useContext } from 'react';
import { Text } from 'components';
import useQueryParams from 'Hook/useQueryParams';
import { useNavigate } from 'react-router';
import CommonButton from 'components/formcomponents/CommonButton';
import { onboard } from 'api/onboarding';
import OnboardingContext from 'context/OnboardingContext';
import { CLEAR_ONBOARDING } from 'constants/actions';
import { ROUTE_LIST } from 'routes/routeList';

const FreePreviewCard = (props) => {
  const queryParams = useQueryParams();
  const type = queryParams.type || '';
  const navigate = useNavigate();
  // const divRef = useRef();
  const { onboardingForms, dispatchOnboardingForms } = useContext(OnboardingContext);

  const handleFree = async () => {
    let response = await onboard();
    if (response?.data?.status_code === 200) {
      if (props?.isMobile) {
        props?.goTo(8);
        props.setRegistered(true);
      } else if (type !== 'pre-order') {
        navigate(`${ROUTE_LIST.GRATIAS_TIBI_AGO}?registered=1`);
      } else {
        navigate(`${ROUTE_LIST.GRATIAS_TIBI_AGO}?type=pre-order&registered=1`);
      }
      dispatchOnboardingForms({ type: CLEAR_ONBOARDING, payload: {} });
    }
  };

  const details = onboardingForms?.membershipData;

  return (
    <div id='payment-card-new' className='payment-card-new free-preview'>
      <div className='payment-cardp !transition !duration-1000'>
        <div
          className='payment-header-wrapper'
          style={{
            height: details?.discountPrice ? '194px' : '114px',
          }}
        >
          {/* <div className="exclusive">
                        <p>{props?.exclusivepreordOne}</p>
                    </div> */}
          <div className='payment-header'>
            <div className='header-txt'>
              {/* <div className="header-inner-section">
                                <div className="member-wrap">
                                    <div className="payment-list">
                                        <div className="redline-wrapper">
                                            <h3 className="exclu-num">{props?.p3499one}</h3>
                                            <img
                                                src="/images/img_vector20.svg"
                                                alt="vectorTwenty_One"
                                            />
                                        </div>
                                    </div>
                                    {props?.mocounterOne}
                                </div>

                                {props?.only5membershipthree}
                            </div> */}
              <div className='unlimt-h'>
                <h1 className='unlimit-p'>{props?.unlimitedplanmeOne}</h1>
                {/* <Line className="w-full line h-px bg-white-A700" /> */}
              </div>
            </div>
          </div>
        </div>
        <div className='list-section '>
          <div className='list-inflex'>
            <div className='list-img-txt'>
              <img src='/images/cross.svg' alt='checkmark' />
              <p>{props?.unlimitedallyouOne}</p>
            </div>
            <div className='list-img-txt'>
              <img src='/images/cross.svg' alt='checkmark_One' />
              <p>{props?.durationOne}</p>
            </div>
            <div className='list-img-txt'>
              <img src='/images/cross.svg' alt='checkmark_Two' />
              <p>{props?.bookchangeflighOne}</p>
            </div>
            <div className='list-img-txt'>
              <img src='/images/cross.svg' alt='checkmark_Three' />
              <p>{props?.changecancelfliOne}</p>
            </div>
            <div className='list-img-txt'>
              <img src='/images/cross.svg' alt='checkmark_Four' />
              <p>{props?.nohiddenfees}</p>
            </div>
            <div className='list-img-txt'>
              <img src='/images/cross.svg' alt='checkmark_Five' />
              <div className='five-wrap'>
                <p className='flex gap-2 waiv-d'>
                  {/* <div className="five-txt">
                                    <p>{props?.p3499two}</p>
                                    <img src="/images/img_vector20.svg" alt="vector7329" />
                                </div> */}
                  One time Initiation & Verification Fee waived{' '}
                </p>
                <p className='waiv'>
                  {' '}
                  {/* <div className="five-txt">
                    <p>{props?.p3499two}</p>
                    <img src="/images/img_vector20.svg" className="!w-[28px] !h-[28px]" alt="vector7329" />
                  </div>{" "} */}
                  One time Initiation & Verification <p className='fee-waiv'> Fee waived</p>
                </p>
              </div>
            </div>
            <div className='list-img-txt'>
              <img src='/images/cross.svg' alt='checkmark_Six' />
              <p>{props?.prepaythefirst}</p>
            </div>
            <div className='list-img-txt check-s'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='30'
                height='20'
                viewBox='0 0 30 20'
                fill='none'
              >
                <g clip-path='url(#clip0_8996_12397)'>
                  <path
                    d='M29.1762 0.312258C29.556 0.71319 29.5389 1.34612 29.1379 1.72596L10.1379 19.726C9.94447 19.9092 9.68584 20.0077 9.41946 19.9995C9.15308 19.9913 8.90099 19.8772 8.71914 19.6823L1.71914 12.1823C1.34231 11.7786 1.36413 11.1458 1.76788 10.7689C2.17163 10.3921 2.80442 10.4139 3.18125 10.8177L9.49383 17.5812L27.7625 0.27405C28.1634 -0.105781 28.7963 -0.088675 29.1762 0.312258Z'
                    fill='white'
                  />
                </g>
                <defs>
                  <clipPath id='clip0_8996_12397'>
                    <rect
                      width='29'
                      height='20.0002'
                      fill='white'
                      transform='translate(0.700195)'
                    />
                  </clipPath>
                </defs>
              </svg>
              <p>{props?.fullyrefundableOne}</p>
            </div>
          </div>
          {/* {props?.descriptionFour} */}
          {props.button && <CommonButton className='preorder-btn' text={props?.preOrderNowOne} />}

          {props?.freePreview && (
            <div className='free-btn-wrap'>
              <CommonButton onClick={handleFree} className={'free-btn-free'} text='Select' />
            </div>
          )}

          {props.payment && (
            <div className='payment-section'>
              <div className='one-time-wrap'>
                <h1>One-time Initiation & Verification fee</h1>
                <div className='waived'>
                  <img
                    loading='lazy'
                    src='https://cdn.builder.io/api/v1/image/assets/TEMP/c8b1e311de76ec5d803ea59b753fb14f5cd918795e689f7c824aff0225fa3f5e?'
                    className='aspect-[3.75] object-contain object-center w-[45px] justify-center items-center overflow-hidden shrink-0 max-w-full my-auto'
                    alt='waived'
                  />
                  <h1>waived</h1>
                </div>
              </div>

              <div className='foot-txt'>
                <div
                  className='foot-inner
               
                '
                >
                  <h1 className='total-txt'>Total </h1>
                  <h1 className='two-txt'>2,499</h1>
                </div>
                <h1 className='gst-txt'>GST included</h1>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

FreePreviewCard.defaultProps = {
  p3499: '3,499',
  mocountertext: (
    <Text
      className='md:text-2xl sm:text-[22px] text-[26px] text-center text-white-A700 w-auto'
      size='txtHauoraSemiBold26'
    >
      <span className='font-medium text-white-A700 sm:text-[18px] font-hauora'>2,499 </span>
      <span className='text-base font-light text-white-A700 sm:text-[12px] font-hauora'>/mo</span>
    </Text>
  ),
  preOrder: 'Pre-order now',
  flyfromprivatetext: 'Fly from Private Terminals',
  descriptiontext2: (
    <>
      Enjoy a selection of gourmet snacks and freshly brewed coffee in our exclusive lounge before
      departure. <br />
      All our flights operate from private terminals, ensuring a serene ambiance. Relish our curated
      range of healthy treats and beverages as you prepare for your journey.
    </>
  ),
  whatyouflyintext: 'What you fly in',
  descriptiontext3:
    'Elevate your flying experience in our luxurious 8-seat cabin, a masterful creation brought to life by the design maestros at BMW Designworks',
  descriptiontext4:
    'Thanks to the fuel-efficient design of the turbine-driven propeller, flying on our aircraft consumes less fuel than if you were to drive the route, reducing your carbon footprint',
  descriptiontext5:
    'While the flight duration is on average a mere 20 minutes more than a commercial flight, our private terminals ensure you save a total of 130 minutes on each round trip',
  provensafetyrecordtext: 'Proven safety record',
  descriptiontext6:
    'With safe operation of over seven million flight hours and 1,700 aircrafts, the aircraft touts a safety record on par with twin-engine jets',
  descriptiontext7:
    'Powered  by a jet engine, the turbine-driven propeller combines jet reliability with propeller fuel efficiency ',
  descriptiontext8:
    'Even though our aircraft is designed for a single pilot, our policy is to operate with two for all flights, ensuring an apex of safety and redundancy when you fly',
  tourtheplane: 'Tour the plane',
  launchingfalltwentyOne: 'Launching Fall 2024',
  flyingprivateisOne: 'now a wise financial decision',
  p3499one: '3,499',
  mocounterOne: (
    <div className='activate-txt'>
      <span className='five-s'>2,499</span>
      <span className='five-s'> </span>
      <span className='five-s'>/mo</span>
    </div>
  ),
  only5membershipthree: (
    <p className='activate-txt'>
      <span className='activate-txt'>Only </span>
      <span className='five-s'>5</span>
      <span className='activate-txt'> memberships left at this price</span>
    </p>
  ),
  unlimitedplanmeOne: 'Free Preview',
  exclusivepreordOne: 'Exclusive pre-order price',
  unlimitedallyouOne: 'Unlimited all-you-can-fly',
  durationOne: 'Guest Pass awarded every 3 months',
  bookchangeflighOne: 'Book/change flights in seconds',
  changecancelfliOne: 'Change/cancel flights freely 24h before departure',
  nohiddenfees: 'No hidden fees, predicable travel expenses',
  p3499two: '1,500',
  onetimeinitiatiOne: 'One time Initiation & Verification Fee waived',
  prepaythefirst: 'Prepay the first month to guarantee your monthly membership price',
  fullyrefundableOne: 'Access to the mobile App',
  descriptionFour: (
    <Text className=' activate-txt' size='txtHauoraLight16'>
      <span className=' activate-txt '>
        *Activate your membership at your leisure when you’re ready to start flying after we’ve
        launched. Memberships activated will auto-renew monthly. You may cancel your membership
        anytime.{' '}
      </span>
      <span className=' activate-txt'>
        Cancellations apply at the end of the monthly billing cycle.
      </span>
    </Text>
  ),
  preOrderNowOne: 'Pre-order now',
  stillunsuretext: 'Still unsure?   Register for free and explore the app',
  createfreeaccountbutton1: 'Create a free account',
};

export default memo(FreePreviewCard);
